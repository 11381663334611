.map-slide__tab-controls>.btn{
    border-radius: 17px;
}

.map-slide__tab-controls > .btn-default{
    background: #b5c7af;
}

.map-slide__tab-controls > .btn-default.active,
.map-slide__tab-controls > .btn-default.active:hover,
.map-slide__tab-controls > .btn-default.active:focus{
    background: #4a6741;
}

.map-slide__tab-controls > .btn-default:active,
.map-slide__tab-controls > .btn-default:hover,
.map-slide__tab-controls > .btn-default:focus{
   background: #b5c7af;
}


.map-slide__tab-controls > .btn-default .icon{
    color: #4a6741;
}


.map-slide__tab-controls > .btn-default.active .icon{
    color: #fff;
}

.map-slide__description__train{
    /*background: url(/static/img/mautern/content/map-train.png) no-repeat;*/
    /*background-size: 100% auto;*/
    /*width: 50%;*/
    /*height: 500px*/
}

.map-slide__img-helper{
    padding-top: 80.52208835341365%;
}

.map-slide__img-holder{
    position: absolute;
    top: -112px;
    left: -50px;
    right: -160px;
}


.map-slide__description__train{
    position: initial;
}

@media screen and (min-width: 768px) {
    .map-slide__description__more{

    }

    .map-slide__description__intro{
        font-size: 26px;
    }

}
