.footer{
    font-size: 0.9375em;
    overflow: hidden;
}

.footer-wrapper{
    z-index: 1;
}

.bg-footer{
    position: relative;
}

.footer__copyright {
    height: 70px;
    color: var(--color-primary-comp);
}

.footer .bg-secondary {
    background: #5b8050;
}


/*.footer-wrapper {*/
    /*background: url(/static/img/mautern/backgrounds/footer-small.png) no-repeat;*/
    /*background-size: 100%;*/
    /*background-position: bottom;*/
/*}*/

.bg-footer:after {
    content: '';
    background-color: #5b8050;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}


.footer-wrapper:after {
    background: url(/static/img/mautern/backgrounds/footer-small.png) no-repeat;
    background-size: 100%;
    background-position: bottom;
    content: '';
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 100%;
    z-index: -1;
}

.footer-wrapper .special-img:before{
    background: url(/static/img/mautern/backgrounds/bg-footer-cartoons.png) no-repeat;
    background-position: right;
    content: '';
    position: absolute;
    width: 196px;
    bottom: 175px;
    right: -106px;
    height: 256px;
}

.footer__col{
    padding-left: 15px;
    padding-right: 15px;
}

.footer__col h3{
    text-transform: uppercase;
}

.footer__col a{
    color: #bd9c4a;
}

.footer-sprite-site-by-elements{
    top: -18px;
    position: relative;
}

.footer .arrange__item{
    position: relative;
    top: 12px;
}

@media screen and (max-width: 767px) {
    .footer__col {
        margin-top: 15px;
        float: none;
    }
    .footer__email {
        width: 200px;
    }

    .footer-wrapper:before{
        display: none;
    }

    .footer__copyright {
        position: relative;
        z-index: 2;
    }
    .bg-footer:after{
        z-index: 0;
    }
    .footer-wrapper .special-img:before{
        display: none;
    }

    .footer-sprite-site-by-elements{
        top: 0;
    }

}
@media screen and (min-width: 768px) {
    .footer-wrapper {
        position: relative;
        padding-top: 50px;
    }
}