
.wysiwyg h1,
.wysiwyg h2,
.wysiwyg h3 {
    text-transform: uppercase;
}

.wysiwyg a {
    color: var(--color-secondary);
}
.wysiwyg a:hover {
    color: var(--color-secondary-dark);
}