.img-slide__hl,
.img-slide__hl strong{
    font-family: TrashHandRegular;
}


@media screen and (min-width: 768px) and (max-width: 992px) {
    #header-slider{
        top: 0;
    }
}