:root {
    --navbar-bg: #5b8050;
    --navbar-border-color: #5b8050;
}


.navbar-main {
    margin-bottom: 0;
    background: var(--navbar-bg);
    border: none;
    border-bottom: 3px solid var(--navbar-border-color);
    border-radius: 0;
    box-shadow: 0 0 5px rgba(0,0,0,.25);
    z-index: 100;
    /*promote to own comp layer for better chrome scrolling rendering*/
    transform: translateZ(0);
}

.navbar-main .navbar-header>.navbar-brand>img {
    max-height: 145px;
    padding-top: 0;
}

@media screen and (min-width: 768px) {
    .navbar__item>a,
    .navbar-nav-main>li>a {
        font-family: var(--font-default-family);
        font-weight: var(--font-bold-weight);
    }
}

@media screen and (min-width: 768px) {
    .navbar-main.affix .navbar-header>.navbar-brand>img {
        height: 120px;
    }
}

@media screen and (max-width: 767px) {
    .navbar-main .navbar-header>.navbar-brand>img {
        max-height: 70px;
    }
}
