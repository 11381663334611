.partner-slide{
    background: rgb(243,247,241); /* Old browsers */
    background: -moz-linear-gradient(top,  rgba(243,247,241,1) 0%, rgba(243,247,241,1) 24%, rgba(255,255,255,1) 60%, rgba(255,255,255,1) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(243,247,241,1)), color-stop(24%,rgba(243,247,241,1)), color-stop(60%,rgba(255,255,255,1)), color-stop(100%,rgba(255,255,255,1))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  rgba(243,247,241,1) 0%,rgba(243,247,241,1) 24%,rgba(255,255,255,1) 60%,rgba(255,255,255,1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  rgba(243,247,241,1) 0%,rgba(243,247,241,1) 24%,rgba(255,255,255,1) 60%,rgba(255,255,255,1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  rgba(243,247,241,1) 0%,rgba(243,247,241,1) 24%,rgba(255,255,255,1) 60%,rgba(255,255,255,1) 100%); /* IE10+ */
    background: linear-gradient(to bottom,  rgba(243,247,241,1) 0%,rgba(243,247,241,1) 24%,rgba(255,255,255,1) 60%,rgba(255,255,255,1) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f3f7f1', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */



    color: var(--color-primary);
}

.partner-slide p{
   color: #262626;
}
.partner-slide a{
    color: #bd9c4a;
}

.partner-slide a:hover{
    color: #d7b254;
}