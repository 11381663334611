:root {
    --color-expandable-panel-hover: #5b8050;
}

.expandable-panel__wrapper .bg-shine{
    background-image: none;
}

@media screen and (min-width: 768px) {
    .expandable-panel__wrapper:hover .expandable-panel__icon {
        background: #5b8050;
    }

    .expandable-panel__icon {
        font-size: 53px;
    }

    .expandable-panel__icon .icon{
        display: inline-block;
        position: relative;
    }
}


@media screen and (max-width: 767px) {
    .expandable-panel__icon-right {
        top: 5px;
    }
    .expandable-panel__icon{
        font-size: 34px;
    }

    .expandable-panel__icon .icon {
        top: 6px;
        position: relative;
    }

    .expandable-panel__header-text>.sub{
        display: none;
    }

    .expandable-panel__header-text {
        padding-top: 15px;
    }
}

@media screen and (max-width: 767px) and (min-width: 321px){
    .expandable-panel__icon-right {
        top: -3px;
    }
}