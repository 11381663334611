:root {
    --teaser-btn-color: #4a6741;
    --highlight-teaser-body-color: #f0c65d;
}

.teaser__btn > .icon {
    font-size: 20px;
}

a.teaser__text.teaser__text {
    color: var(--color-dark-grey);
}


.teaser:hover .teaser__btn {
    background-color: #f0c65d;
}

.teaser--highlight:hover .teaser__btn {
    background: var(--teaser-btn-color);
    color: var(--color-primary-comp);
    box-shadow: none;
}

.teaser--highlight,
.teaser--highlight .teaser__body,
.teaser--higlight-on-hover:hover,
.teaser--higlight-on-hover:hover .teaser__body{
    background-color: var(--highlight-teaser-body-color);
    color: var(--color-secondary-comp);
}