
.slide--bg-owl {
    background: url(/static/img/mautern/backgrounds/slider-owl.png) no-repeat;
    background-size: 100% auto;
    background-position: bottom;
}

@media screen and (max-width: 767px) {
    .slide--bg-owl {
        background: none;
    }
}
