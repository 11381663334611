.content-teaser h3{
    text-transform: uppercase;
}

.content-teaser a{
     color: var(--color-secondary-dark);
 }

.content-teaser a:hover{
    color: var(--color-secondary);
}

.content-teaser.bg-info {
    background: var(--color-info);
}
.content-teaser.bg-info:hover {
    background-color: #f5f8f9;
}
