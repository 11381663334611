.special-img {
    position: relative;
}
.special-img--right:after {
    content: url('/static/img/mautern/content/murmeltier.png');
    position: absolute;
    right: 130px;
    bottom: -73px;
    z-index: -1;
}


@media screen and (max-width: 1600px) {
    .special-img--right:after {
        display: none;
    }
}
