.btn-primary {
    background: var(--color-primary);
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active {
    background: var(--color-primary-dark);
}
.btn-primary>.btn__icon:after {
    background: #6e9960;
}

.no-cursor {
    cursor: default;
}