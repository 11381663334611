:root {
    --img-poi-bg-color: rgba(0,0,0,.5);
    --img-poi-bg-color-fallback: #000;
    --img-poi-bg-active: var(--color-secondary);
}
.img-poi {
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    background: #ffffff;
    color: #ffffff;
    box-shadow:  0 0 0 5px rgba(240, 198, 93, .6);
}
.img-poi__content {
    display: block;
    position: absolute;
    bottom: 42px;
    left: -18px;
    background: var(--img-poi-bg-color-fallback);
    background: var(--img-poi-bg-color);
    /*color: #ffffff;*/
    white-space: nowrap;
    padding: 3px 15px 2px 15px;
    border-radius: 20px;
}
.img-poi__content:after {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    left: 16px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 11px 0 11px;
    border-color: var(--img-poi-bg-color-fallback) transparent transparent transparent;
    border-color: var(--img-poi-bg-color) transparent transparent transparent;
}
/*hover*/
a.img-poi:hover {
    box-shadow:  0 0 0 5px var(--img-poi-bg-active);
}
a.img-poi:hover>.img-poi__content,
a.img-poi:focus>.img-poi__content{
    background: var(--img-poi-bg-active);
    color: var(--color-primary-comp);
}
a.img-poi:hover>.img-poi__content:after,
a.img-poi:focus>.img-poi__content:after {
    border-color: var(--img-poi-bg-active) transparent transparent transparent;
}
a.img-poi:hover, a.img-poi:focus {
    box-shadow:  0 0 0 5px var(--img-poi-bg-active);
}
.img-poi:focus { outline: none; }