.console__item {
    position: static;
}

.console__social-icon .icon-social_twitter{
    position: relative;
    top: 5px;
}

@media screen and (max-width: 767px) {
    .console__icon{
        padding-left: 7px;
        padding-right: 7px;
    }
}

@media screen and (min-width: 1200px) {
    .console__icon.icon-konsole_share {
        font-size: 25px;
    }
}